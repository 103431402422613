import { NavLink } from 'react-router-dom'

import './navbar.css'
import logo from '../../Assets/Navbar/logo.png'

const Navbar = () => {

    const scrollTop = () => window.scrollTo(0, 0)

    const active = {
        color: 'white',
        background: '#760014'
    }

    return (
        <div className="navbar">
            <div className="navbar-content">
                <NavLink className="logo-container" to="/" onClick={() => scrollTop()}>
                    <img src={logo} alt="High Definition Technology"/>
                </NavLink>

                <div className="button-container">
                    <NavLink style={({isActive}) => isActive ? active : undefined} to="/about" onClick={() => scrollTop()}>about us</NavLink>

                    <NavLink style={({isActive}) => isActive ? active : undefined} to="/contact" onClick={() => scrollTop()}>contact us</NavLink>

                    <NavLink style={({isActive}) => isActive ? active : undefined} to="/service" onClick={() => scrollTop()}>our service</NavLink>
                </div>
            </div>
        </div>
    )
}
export default Navbar;