import Navbar from '../Navbar/navbar'
import Banner from './Banner'
import BusinessActivity from './BusinessActivity.js'
import WhyUs from './WhyUs'
import Record from '../Record/record'
import Footer from '../Footer/footer'
import ScrollTop from '../Scrolltop/scrolltop'

import './home.css'

const Home = ({service, setService}) => {
    return (
        <>
            <Navbar/>

            <div className="home">
                <Banner />

                <div className="breakpoint"></div>

                <BusinessActivity setService={setService} service={service} />

                <div className="breakpoint"></div>

                <WhyUs />

                <div className="breakpoint"></div>

                <Record />
            </div>

            <Footer/>

            <ScrollTop />
        </>
    )
}
export default Home;