import { useEffect, useRef, useState } from 'react'
import { Icon } from '@iconify/react'
import emailjs from '@emailjs/browser';
import validator from 'validator';
import gsap from 'gsap'
import { toast, ToastContainer } from 'react-toastify';

import Navbar from '../Navbar/navbar'

import './contact.css'
import 'react-toastify/dist/ReactToastify.css'

const Contact = () => {
    useEffect(() => {
        gsap.fromTo('.header-container',
        {
            x: 100,
            opacity: 0,
        },
        {
            duration: 2,
            x: 0,
            opacity: 1,
            ease: "power4.out",
        })

        gsap.fromTo('.form-row',
        {
            opacity: 0,
        },
        {
            duration: 2,
            opacity: 1,
            stagger: 0.3,
            ease: "power4.out",
        })

        gsap.fromTo('.contact-info, .email-info',
        {
            opacity: 0,
        },
        {
            duration: 2,
            opacity: 1,
            stagger: 0,
            ease: "power4.out",
        })
    }, [])

    const form = useRef()

    // input value and onblur value
    const [name, nameInput, nameTouch, setNameTouch] = useInput({ type: 'text' }, 'name')
    const [email, emailInput, emailTouch, setEmailTouch] = useInput({ type: 'email' }, 'email')
    const [phone, phoneInput, phoneTouch, setPhoneTouch] = useInput({ type: 'phone' }, 'phone')
    const [msg, msgInput, msgTouch, setMsgTouch] = useInput({ type: 'text' }, 'message')

    // input form error
    const [errName, setErrName] = useState(null)
    const [errEmail, setErrEmail] = useState(null)
    const [errPhone, setErrPhone] = useState(null)
    const [errMsg, setErrMsg] = useState(null)

    // input contain error
    const [nameContainError, setNameContainError] = useState(false)
    const [emailContainError, setEmailContainError] = useState(false)
    const [phoneContainError, setPhoneContainError] = useState(false)
    const [msgContainError, setMsgContainError] = useState(false)

    // return error on 'true' input onblur value
    useEffect(() => {
        if (nameTouch) {
            if (name.length < 8) {
                setErrName('8 Characters Minimum')
                setNameTouch('visited')
                setNameContainError(true)
            } else {
                setErrName(null)
                setNameContainError(false)
            }
        } else if (nameTouch === 'visited') {
            if (name.length < 8) {
                setErrName('8 Characters Minimum')
                setNameContainError(true)
            } else {
                setErrName(null)
                setNameContainError(false)
            }
        }

        if (emailTouch) {
            if (!validator.isEmail(email)) {
                setErrEmail('Invalid Email')
                setEmailTouch('visited')
                setEmailContainError(true)
            } else {
                setErrEmail(null)
                setEmailContainError(false)
            }
        } else if (emailTouch === 'visited') {
            if (!validator.isEmail(email)) {
                setErrEmail('Invalid Email')
                setEmailTouch('visited')
                setEmailContainError(true)
            } else {
                setErrEmail(null)
                setEmailContainError(false)
            }
        }

        if (phoneTouch) {
            if (!validator.isMobilePhone(phone)) {
                setErrPhone('Invalid Phone Number')
                setPhoneTouch('visited')
                setPhoneContainError(true)
            } else {
                setErrPhone(null)
                setPhoneContainError(false)
            }
        } else if (phoneTouch === 'visited') {
            if (!validator.isMobilePhone(phone)) {
                setErrPhone('Invalid Phone Number')
                setPhoneContainError(true)
            } else {
                setErrPhone(null)
                setPhoneContainError(false)
            }
        }

        if (msgTouch) {
            if (msg.length < 10) {
                setErrMsg('Insert more words')
                setMsgTouch('visited')
                setMsgContainError(true)
            } else {
                setErrMsg(null)
                setMsgContainError(false)
            }
        } else if (msgTouch === 'visited') {
            if (msg.length < 10) {
                setErrMsg('Insert more words')
                setMsgContainError(true)
            } else {
                setErrMsg(null)
                setMsgContainError(false)
            }
        }
    }, [nameTouch,
        emailTouch,
        phoneTouch,
        msgTouch,
        setNameTouch,
        setEmailTouch,
        setPhoneTouch,
        setMsgTouch,
        name,
        email,
        phone,
        msg
    ])

    const sendEmail = (e) => {
        e.preventDefault()

        const emailjsDetail = {
            serviceID: 'service_ogsy3le',
            templateID: 'template_2xhdl5i',
            publicKEY: '3btdsNoISlzgdQNU1'
        }

        if (nameContainError === true || emailContainError === true || phoneContainError === true || msgContainError === true) {
            toast.error('Error in Message Form', {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 5000
            })
        } else {
            emailjs.sendForm(emailjsDetail.serviceID, emailjsDetail.templateID, form.current, emailjsDetail.publicKEY)
            .then(
                (result) => {
                    toast.success('Message Sent Successfully', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        hideProgressBar: true,
                        autoClose: 5000
                    })

                    for (let i = 0; i < 4; i++) {
                        e.target[i].value = ''
                    }
                },
                (error) => {
                    toast.error('Error Sending Message (', error.text , ')', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        hideProgressBar: true,
                        autoClose: 5000
                    })

                    for (let i = 0; i < 4; i++) {
                        e.target[i].value = ''
                    }
                }
            )

        }
    }

    return (
        <>
            <Navbar/>

            <div className="contact">
                <div id="business-header" className="header-container" >
                    <div className="main-header">get in <span className="red">touch!</span></div>
                </div>

                <div className="contact-container">
                    <div className="form-container">
                        <div className="form-container-content">
                            <div className="form-content">
                                <form className="form-contact" onSubmit={sendEmail} ref={form}>
                                    <div className="header-container">
                                        <div className="main-subheader"><b>Have some feedback or a general question? Send us a message below</b></div>
                                    </div>

                                    <div className="form-row">
                                        <div className="label-error">
                                            <label htmlFor="name">Name</label>
                                            <span className="error-name">{errName}</span>
                                        </div>
                                        {nameInput}
                                    </div>

                                    <div className="form-row">
                                        <div className="label-error">
                                            <label htmlFor="email">Email</label>
                                            <span className="error-name">{errEmail}</span>
                                        </div>
                                        {emailInput}
                                    </div>

                                    <div className="form-row">
                                        <div className="label-error">
                                            <label htmlFor="phone">Phone</label>
                                            <span className="error-name">{errPhone}</span>
                                        </div>
                                        {phoneInput}
                                    </div>

                                    <div className="form-row">
                                        <div className="label-error">
                                            <label htmlFor="message">Message</label>
                                            <span className="error-name">{errMsg}</span>
                                        </div>
                                        {msgInput}
                                    </div>

                                    <div className="form-row">
                                        <button type="submit">send</button>
                                    </div>
                                </form>
                            </div>

                            <div className="contact-info">
                                <div className="email-info">
                                    <a href="mailto:roslee@highdefinition.com.my"><Icon icon="ic:round-email" width="20" /> roslee@highdefinition.com.my</a>
                                </div>

                                <div className="phone-info">
                                    <a href="tel:+603-4270 6883"><Icon icon="ic:round-phone" width="20" /> +603-4270 6883</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="map-container">
                        <div className="map-info">
                            <iframe
                                title="map"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1101.75797300729!2d101.75260686176841!3d3.1582920399254357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc3722aeb56bc5%3A0x362cf9000a18d74d!2sHigh%20Definition%20Technology%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1673493945389!5m2!1sen!2smy"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </div>
                </div>
                <ToastContainer/>
            </div>
        </>
    )

    // form input
    function useInput({ type /*...*/ }, name, reset) {
        const [touch, setTouch] = useState(false)
        const [value, setValue] = useState("")
        const className = 'form-' + name
        const input = name !== 'message'
        ?   <input
                className={className}
                value={reset ? '' : value}
                onChange={e => setValue(e.target.value)}
                type={type}
                id={name}
                name={name}
                onBlur={() => setTouch(true)}
                required
            />
        :   <textarea
                className={className}
                value={reset ? '' : value}
                onChange={e => setValue(e.target.value)}
                type={type}
                id={name}
                name={name}
                onBlur={() => setTouch(true)}
                required
            ></textarea>
        return [value, input, touch, setTouch]
    }
}

export default Contact