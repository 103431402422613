import gsap from 'gsap';
import { useRef, useEffect, useState } from 'react';

import useOnScreen from '../../Hooks/useOnScreen.js';
import './record.css';

const Record = () => {
  const headerRef = useRef(null);

  const [headerReveal, setHeaderReveal] = useState(false);

  const headerOnScreen = useOnScreen(headerRef);

  useEffect(() => {
    if (headerOnScreen) setHeaderReveal(headerOnScreen);
  }, [headerOnScreen]);

  useEffect(() => {
    if (headerReveal) {
      gsap.fromTo(
        '#record-header',
        {
          x: 50,
          opacity: 0,
        },
        {
          duration: 2,
          x: 0,
          opacity: 1,
          stagger: 0.1,
          ease: 'power4.out',
          scrollTrigger: {
            trigger: '.record',
            scrub: true,
            invalidateOnRefresh: true, // to make
          },
        }
      );
    }
  }, [headerReveal]);

  useEffect(() => {
    gsap.fromTo(
      '.records .record',
      {
        x: -100,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        stagger: 0.3,
        ease: 'power4.out',
        scrollTrigger: {
          trigger: '.records',
          scrub: true,
          end: 'bottom center-=-100px',
          invalidateOnRefresh: true, // to make it responsive
        },
      }
    );
  }, []);

  return (
    <div className="track-record">
      <div className="record-container">
        <div id="record-header" className="header-container" ref={headerRef}>
          <span className="main-header">track records</span>

          <span className="main-subheader">HDTech's previous projects</span>
        </div>

        <div id="record" className="record-content">
          <div className="records">
            <span className="record">
              Design, Supply, Deliver, Install, Test and Commissioning of
              "Mobile TV Transmitter (UHF 10kW and VHF 5kW)
            </span>

            <span className="record">
              Supply of spare parts for FM/ TV Transmitters & Program Input
              Equipment & Monitoring
            </span>

            <span className="record">
              Sebutharga Membekal Alat Ganti Pemancar Eurotel Power Amplifier
            </span>

            <span className="record">
              Tender for the supply of spare parts for FM/TV Transmitters,
              Shortwave Transmitters & Program Input Equipment & Monitoring
            </span>

            <span className="record">
              Tender for the supply, delivery, installation, testing and
              commissioning of TV Transposers Systems and Associated Works
            </span>

            <span className="record">
              Sebutharga membangun, membekal dan menghantar e-Content bagi mata
              pelajaran Biologi
            </span>

            <span className="record">
              Supply, Delivery, Installation, Testing & Commissioning of 100 KW
              Shortwave HF Broadcast Transmitter Systems
            </span>

            <span className="record">
              Supply, testing and commissioning of Central Data Storage
            </span>

            <span className="record">
              Supply, develop, test teaching Form 5 Biology courseware for
              Secondary School
            </span>

            <span className="record">Market Data Solution</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Record;
