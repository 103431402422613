import Navbar from '../Navbar/navbar'
import Footer from '../Footer/footer'
import Record from '../Record/record'
import Banner from './Banner'
import Corpo from './Corpo'
import ScrollTop from '../Scrolltop/scrolltop'

import './about.css'

const About = () => {
    return (
        <>
            <Navbar/>

            <div className="about">
                <Banner />

                <div className="breakpoint"></div>

                <Corpo />

                <div className="breakpoint"></div>

                <Record />
            </div>

            <Footer/>

            <ScrollTop />
        </>
    )
}
export default About