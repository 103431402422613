import gsap from 'gsap'
import { useRef, useState, useEffect } from 'react'

import useOnScreen from '../../Hooks/useOnScreen.js'
import './footer.css'

const Footer = () => {

    const footerRef = useRef(null)

    const [footerReveal, setfooterReveal] = useState(false)

    const footerOnScreen = useOnScreen(footerRef)

    useEffect(() => {
        if (footerOnScreen) setfooterReveal(footerOnScreen)
    }, [footerOnScreen])

    useEffect(() => {
        if (footerReveal) {
            gsap.fromTo('#footer-content',
            {
                y: 50,
                opacity: 0,
            },
            {
                duration: 2,
                y: 0,
                opacity: 1,
                stagger: 0.3,
                ease: "power4.out",
                scrollTrigger: {
                    trigger: '.footer',
                    scrub: true
                }
            })
        }
    }, [footerReveal])

    return (
        <div className="footer" ref={footerRef}>
            <div className="footer-content">
                <span className="title-container">contact us</span>

                <div className="content-container">
                    <div id="footer-content" className="detail-container">
                        <span className="detail-header">High Definition Technology Sdn Bhd. (700893H)</span>

                        <div className="address">
                            <span className="detail-subheader">HQ Address</span>

                            <span className="detail-desc">
                                A-11-3A, Level 4,<br/>
                                Jalan Selaman 1/1, Dataran Palma,<br/>
                                68000 Ampang,<br/>
                                Selangor, Malaysia<br/>
                            </span>
                        </div>

                        <div className="line">
                            <span className="detail-subheader">Service Line</span>

                            <span className="detail-desc">+603 4270 6883</span>
                        </div>
                    </div>

                    <div className="map-container">
                        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1101.75797300729!2d101.75260686176841!3d3.1582920399254357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc3722aeb56bc5%3A0x362cf9000a18d74d!2sHigh%20Definition%20Technology%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1673493945389!5m2!1sen!2smy" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer;