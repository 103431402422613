import { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Pagination } from "swiper";
// import { Tree, TreeNode } from 'react-organizational-chart'

import 'swiper/css'
import useOnScreen from '../../Hooks/useOnScreen.js'
import w from '../../Hooks/useWindowDimensions'

import root from '../../Assets/About/root.png'
import technical from '../../Assets/About/technical.png'
import software from '../../Assets/About/software.png'
import help from '../../Assets/About/help.png'
import arrow from '../../Assets/About/arrow.png'
// import chart from '../../Assets/About/corpo-chart.png'

const Corpo = () => {

    const headerRef = useRef(null)

    const [headerReveal, setHeaderReveal] = useState(false)

    const headerOnScreen = useOnScreen(headerRef)

    useEffect(() => {
        if (headerOnScreen) setHeaderReveal(headerOnScreen)
    }, [headerOnScreen])

    useEffect(() => {
        if (headerReveal) {
            gsap.fromTo('#header-corpo',
            {
                x: 50,
                opacity: 0,
            },
            {
                duration: 2,
                x: 0,
                opacity: 1,
                stagger: 0.1,
                ease: "power4.out",
                scrollTrigger: {
                    trigger: '.corpo',
                    scrub: true,
                    invalidateOnRefresh: true // to make
                }
            })
        }
    }, [headerReveal])

    const [mobile, setMobile] = useState(w().width < 600 ? true : false)

    var widthHook = w().width

    // change frame width
    useEffect(() => {
        const width = window.innerWidth
        setMobile(width < 600 ? true : false)
    }, [widthHook])

    return (
        <div className="corpo">
            <div className="chart-container">
                <div id="header-corpo" className="header-container" ref={headerRef}>
                    <div className="main-header">corporate structure</div>

                    <div className="main-subheader">High Definition Technology SDN BHD.</div>
                </div>

                <div className="chart">
                    {/* <img src={chart} alt="HD Tech Corporate Structure" /> */}
                    {/* <Swiper
                        slidesPerView={"auto"}
                        spaceBetween={20}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[FreeMode, Pagination]}
                        className="swipes"
                    >
                        <SwiperSlide className="swipe">
                            <Tree
                                lineWidth={'1px'}
                                lineColor={'#760014'}
                                lineBorderRadius={'0'}
                                lineHeight={'50px'}
                                nodePadding={'5px'}
                                label={<span className="tree-root">Managing Director</span>}
                            >
                                <TreeNode label={<span className="tree-orange" >HR & General Administration</span>}/>

                                <TreeNode label={<span className="tree-blue" >Technical</span>}/>

                                <TreeNode label={<span className="tree-pink" >Software</span>}/>

                                <TreeNode label={<span className="tree-green" >Customer Services</span>}/>

                                <TreeNode label={<span className="tree-orange" >Business Development</span>}/>

                                <TreeNode label={<span className="tree-orange" >Finance & Account</span>}/>
                            </Tree>
                        </SwiperSlide>
                        <SwiperSlide className="swipe">
                            <Tree
                                lineWidth={'1px'}
                                lineColor={'#760014'}
                                lineBorderRadius={'0'}
                                lineHeight={'50px'}
                                nodePadding={'5px'}
                                label={<span className="tree-blue">Technical</span>}
                            >
                                <TreeNode label={<span className="tree-blue" >Technical Services</span>}/>

                                <TreeNode label={<span className="tree-blue" >Network</span>}>
                                    <TreeNode label={<span className="tree-blue" >IP</span>}/>

                                    <TreeNode label={<span className="tree-blue" >LAN / WAN</span>}/>

                                    <TreeNode label={<span className="tree-blue" >Satellite</span>}/>

                                    <TreeNode label={<span className="tree-blue" >Radio</span>}/>
                                </TreeNode>

                                <TreeNode label={<span className="tree-blue" >Transmission</span>}>
                                    <TreeNode label={<span className="tree-blue" >Wireless</span>}/>
                                </TreeNode>

                                <TreeNode label={<span className="tree-blue" >Broadcast</span>}>
                                    <TreeNode label={<span className="tree-blue" >TV</span>}/>

                                    <TreeNode label={<span className="tree-blue" >IpTV</span>}/>
                                </TreeNode>
                            </Tree>
                        </SwiperSlide>
                        <SwiperSlide className="swipe">
                            <Tree
                                lineWidth={'1px'}
                                lineColor={'#760014'}
                                lineBorderRadius={'0'}
                                lineHeight={'50px'}
                                nodePadding={'5px'}
                                label={<span className="tree-pink">Software</span>}
                            >
                                <TreeNode label={<span className="tree-pink" >Research & Development</span>}/>

                                <TreeNode label={<span className="tree-pink" >Software Architect & Design</span>}/>

                                <TreeNode label={<span className="tree-pink" >Creative Department</span>}>
                                    <TreeNode label={<span className="tree-pink" >Multimedia Animation</span>}/>
                                    <TreeNode label={<span className="tree-pink" >Web-based Services</span>}/>
                                    <TreeNode label={<span className="tree-pink" >e-Learning</span>}/>
                                </TreeNode>
                            </Tree>
                        </SwiperSlide>
                        <SwiperSlide className="swipe">
                            <Tree
                                lineWidth={'1px'}
                                lineColor={'#760014'}
                                lineBorderRadius={'0'}
                                lineHeight={'50px'}
                                nodePadding={'5px'}
                                label={<span className="tree-green">Customer Service</span>}
                            >
                                <TreeNode label={<span className="tree-green" >Online Help</span>}/>

                                <TreeNode label={<span className="tree-green" >Product Training</span>}/>
                            </Tree>
                        </SwiperSlide>
                    </Swiper> */}
                    <Swiper
                        slidesPerView={mobile ? "1" : "2"}
                        spaceBetween={20}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[FreeMode, Pagination]}
                        className="swipes"
                    >
                        <SwiperSlide className="swipe">
                            <span className="swipe-title">company structure</span>

                            <div className="swipe-image">
                                <img src={root} alt="Top Management Structure"/>
                            </div>

                            <div className="swipe-arrow">
                                <span className="num">1</span>
                                <img src={arrow} alt="Next Slide"/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="swipe">
                            <span className="swipe-title">technical structure</span>

                            <div className="swipe-image">
                                <img src={technical} alt="Technical Structure"/>
                            </div>

                            <div className="swipe-arrow">
                                <span className="num">2</span>
                                <img src={arrow} alt="Next Slide"/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="swipe">
                            <span className="swipe-title">software structure</span>

                            <div className="swipe-image">
                                <img src={software} alt="Software Structure"/>
                            </div>

                            <div className="swipe-arrow">
                                <span className="num">3</span>
                                <img src={arrow} alt="Next Slide"/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="swipe">
                            <span className="swipe-title">customer service structure</span>

                            <div className="swipe-image">
                                <img src={help} alt="Customer Service Structure"/>
                            </div>

                            <div className="swipe-arrow">
                                <span className="num">4</span>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}
export default Corpo