import { useEffect } from "react"
import gsap from "gsap"

import antenna from '../../Assets/Home/antenna.png'
import dishes from '../../Assets/Home/dishes.png'
import light from '../../Assets/Home/light_cloud.png'
import dark from '../../Assets/Home/dark_cloud.png'

const Banner = () => {
    useEffect(() => {
        gsap.fromTo('.header, .subheader',
        {
            x: -100,
            opacity: 0
        },
        {
            duration: 1,
            x: 0,
            opacity: 1,
            stagger: 0.5,
            ease: "power2",
        })
    }, [])

    useEffect(() => {
        gsap.fromTo(['.dishes', '.dark', '.antenna', '.light'],
        {
            x: 50,
            opacity: 0,
        },
        {
            duration: 1,
            x: 0,
            opacity: 1,
            stagger: 0.5,
            ease: "power2",
        })
    }, [])

    return (
        <div className="banner">
            <div className="banner-container">
                <div className="text">
                    <span className="header">Provides the Best IT and Broadcasting Infrastructure</span>

                    <span className="subheader">Provides the Best IT and Broadcasting Infrastructure</span>
                </div>

                <div className="content">
                    <img className="light" src={light} alt="" />
                    <img className="antenna" src={antenna}  alt="" />
                    <img className="dark" src={dark}  alt="" />
                    <img className="dishes" src={dishes}  alt="" />
                </div>
            </div>
        </div>
    )
}
export default Banner