import gsap from 'gsap'
import { useEffect } from 'react'

import connect from '../../Assets/About/connectivity.png'

const Banner = () => {

    useEffect(() => {
        gsap.fromTo('.img-column',
            {
                x: -100,
                opacity: 0,
            },
            {
                duration: 2,
                x: 0,
                opacity: 1,
                ease: "power4.out",
            }
        )

        gsap.fromTo('.banner-text .vm-container',
            {
                x: 100,
                opacity: 0,
            },
            {
                duration: 2,
                x: 0,
                opacity: 1,
                stagger: .2,
                ease: "power4.out",
            }
        )
    }, [])

    return (
        <div className="about-banner">
            <div className="banner-container">

                <div className="content">
                    <div className="img-column">
                        <img className="content-img" src={connect} alt="hd technology"/>
                    </div>

                    <div className="banner-text">
                        <div className="vm-container">
                            <span>vision</span>

                            <p>HDTech's philosophy is that learning enables knowledge, knowledge enables progression, and progression enables companies and individuals to be more effective and add measurable business value. Our value proposition is simple, <b>to offer the highest quality learning solutions, which add knowledge and thus business value to our clients, at the lowest possible cost</b>. As a new market entrant, incorporated in 2005, we are well placed to utilise lower cost bases to deliver on our value proposition.</p>
                        </div>

                        <div className="vm-container">
                            <span>mission</span>

                            <p>At HDTech, we build technology solutions around core business strategies; never the other way around. For us, excellence means harnessing the power of technology to fundamentally improve how our customers do business; o to breathe life into technology in ways that can change how people live, work, and interact. With a distinctly user centric approach to state-of-the-art architecture, user experience modeling and technology, We intend <b>to create meaningful connections between people, ideas, and technology</b>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Banner