import gsap from 'gsap'
import { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import b1 from '../../Assets/Home/b1.png'
import b2 from '../../Assets/Home/b2.png'
import b3 from '../../Assets/Home/b3.png'
import b4 from '../../Assets/Home/b4.png'
import b5 from '../../Assets/Home/b5.png'
import b6 from '../../Assets/Home/b6.png'

import useOnScreen from '../../Hooks/useOnScreen.js'

const BusinessActivity = ({service, setService}) => {
    const headerRef = useRef(null)

    const [headerReveal, setHeaderReveal] = useState(false)

    const headerOnScreen = useOnScreen(headerRef)

    useEffect(() => {
        if (headerOnScreen) setHeaderReveal(headerOnScreen)
    }, [headerOnScreen])

    useEffect(() => {
        if (headerReveal) {
            gsap.fromTo('#business-header',
            {
                x: 50,
                opacity: 0,
            },
            {
                duration: 2,
                x: 0,
                opacity: 1,
                stagger: 0.1,
                ease: "power4.out",
                scrollTrigger: {
                    trigger: '.activity',
                    scrub: true
                }
            })

            gsap.fromTo('.activity',
            {
                x: 50,
                opacity: 0,
            },
            {
                duration: 2,
                x: 0,
                opacity: 1,
                stagger: 0.3,
                ease: "power4.out",
                scrollTrigger: {
                    trigger: '.activity',
                    end: 'bottom center',
                    scrub: true
                }
            })
        }
    }, [headerReveal])

    return (
        <div className="activity">
            <div className="activity-container">
                <div id="business-header" className={cn("header-container", { "is-reveal": headerReveal })} ref={headerRef} >
                    <div className="main-header">business activities</div>

                    <div className="main-subheader">HDTech has focused on six key areas of Information Technology, namely:-</div>
                </div>

                <div className="activities" >
                    <div className="activity">
                        <Link className="activity-content" onClick={() => setService(0)} to="/service">
                            <div className="img">
                                <img src={b1} alt="IT and e-Commerce Services" />
                            </div>

                            <span className="name">IT and e-Commerce Services</span>
                        </Link>
                    </div>

                    <div className="activity">
                        <Link className="activity-content" onClick={() => setService(1)} to="/service">
                            <div className="img">
                                <img src={b2} alt="Software" />
                            </div>

                            <span className="name">Software</span>
                        </Link>
                    </div>

                    <div className="activity">
                        <Link className="activity-content" onClick={() => setService(2)} to="/service">
                            <div className="img">
                                <img src={b3} alt="System Integrations" />
                            </div>

                            <span className="name">System Integrations</span>
                        </Link>
                    </div>

                    <div className="activity">
                        <Link className="activity-content" onClick={() => setService(3)} to="/service">
                            <div className="img">
                                <img src={b4} alt="Networking and Communications" />
                            </div>

                            <span className="name">Networking and Communications</span>
                        </Link>
                    </div>

                    <div className="activity">
                        <Link className="activity-content" onClick={() => setService(5)} to="/service">
                            <div className="img">
                                <img src={b5} alt="Broadcasting, Transmission and IPTV" />
                            </div>

                            <span className="name">Broadcasting, Transmission and IPTV</span>
                        </Link>
                    </div>

                    <div className="activity">
                        <Link className="activity-content" onClick={() => setService(6)} to="/service">
                            <div className="img">
                                <img src={b6} alt="Content Creation" />
                            </div>

                            <span className="name">Content Creation</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BusinessActivity