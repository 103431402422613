import { Icon } from '@iconify/react'
import gsap from 'gsap'
import { useEffect, useState } from 'react'

import './scrolltop.css'

const ScrollTop = () => {
    //get window scroll value
    const [scroll, setScroll] = useState(0)

    const [display, setDisplay] = useState(true)

    useEffect(() => {
        if (scroll > 300 && display === false) {
            setDisplay(true)
            gsap.to('.scroll-container',
                {
                    opacity: 1,
                    duration: 0.5,
                    ease: "power4.out",
                }
            )
        } else if (scroll <= 300 && display === true) {
            setDisplay(false)
            gsap.to('.scroll-container',
                {
                    opacity: 0,
                    duration: 0.1,
                    ease: "power4.out",
                }
            )
        }
    }, [scroll, display])

    window.addEventListener("scroll", function() {
        setScroll((window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop)
    })

    //animation to scroll top
    const scrollTop = () => {
        gsap.to(
            window,
            {
                duration: 2,
                scrollTo: {y: 0},
                ease: "power2.out"
            }
        )
    }


    return (
        <div className="scroll-container" onClick={() => scrollTop()}>
            <Icon icon="ion:caret-up-sharp" color="white" width="10"/>
        </div>
    )
}
export default ScrollTop;