import gsap from 'gsap'
import { useRef, useState, useEffect } from 'react'
import cn from 'classnames'

import SplitText from '../../Utils/Split3.min.js'
import useOnScreen from '../../Hooks/useOnScreen.js'

const WhyUs = () => {
    const headerRef = useRef(null)

    const [headerReveal, setHeaderReveal] = useState(false)

    const headerOnScreen = useOnScreen(headerRef)

    useEffect(() => {
        if (headerOnScreen) setHeaderReveal(headerOnScreen)
    }, [headerOnScreen]);

    useEffect(() => {
        if (headerReveal) {
            gsap.fromTo('#whyus-header',
            {
                x: -50,
                opacity: 0,
            },
            {
                duration: 2,
                x: 0,
                opacity: 1,
                stagger: 0.1,
                ease: "power4.out",
                scrollTrigger: {
                    trigger: '.whyUs',
                    start: 'top bottom',
                    scrub: true
                }
            })

            const split = new SplitText("#desc", { type: "lines" })

            gsap.fromTo(split.lines,
            {
                opacity: 0,
                y: -20
            },
            {
                duration: 2,
                y: 0,
                opacity: 1,
                stagger: 0.1,
                ease: "power4.out",
                scrollTrigger: {
                    trigger: '.whyUs',
                    start: 'top bottom',
                    scrub: true
                }
            })
        }
    }, [headerReveal])

    return (
        <div className="whyUs">
            <div className="whyUs-container">
                <div className="content-abs">
                    <div className="content">
                        <span id="whyus-header" className="header" ref={headerRef} >
                            why us
                        </span>

                        <div className="desc-container">
                            <span id="desc" className={cn("description", { "is-reveal": headerReveal })} >
                                At HDTech, we build technology solutions around core business strategies; never the other way around. For us, excellence means harnessing the power of technology to fundamentally improve how our customers do business; o to breathe life into technology in ways that can change how people live, work, and interact. With a distinctly user centric approach to state-of-the-art architecture, user experience modelling and technology, We intend to create meaningful connections between people, ideas, and technology.
                            </span>
                        </div>
                    </div>
                </div>

                <div className="bg">
                    <div className="red"></div>

                    <div className="white"></div>
                </div>
            </div>
        </div>
    )
}
export default WhyUs