import { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import gsap from 'gsap'

import './service.css'
import w from '../../Hooks/useWindowDimensions'
import Navbar from '../Navbar/navbar'
import Footer from '../Footer/footer'
import ScrollTop from '../Scrolltop/scrolltop'

import b1 from '../../Assets/Service/b1-red.png'
import b2 from '../../Assets/Service/b2-red.png'
import b3 from '../../Assets/Service/b3-red.png'
import b4 from '../../Assets/Service/b4-red.png'
import b5 from '../../Assets/Service/b5-red.png'
import b6 from '../../Assets/Service/b6-red.png'
import b7 from '../../Assets/Service/b7-red.png'
import iptv from '../../Assets/Service/iptv.png'

const Service = ({service, setService}) => {

    const data = [
        {
            'button': 'IT and e-Commerce Service',
            'subheader': 'Source for custom and standard computing needs.',
            'img': b1,
            'content': <>
                <p>Our IT department enjoys the reputation of state-of-the-art engineering, excellence in services, and competitive pricing support all your computing and IT initiatives:-</p>

                <ul>
                    <li>In a design phase of your project, we can provide engineering assistance and a visit vast knowledge base.</li>
                    <li>If your project is already specified and ready for quote - we are uniquely positioned to meet your deadlines. and your bottom line.</li>
                </ul>

                <p>Products range from the widely acclaimed electronics providers to the exclusive, tailor made, highly specialised IT systems. We have been cooperating with major companies worldwide as well as small and ground breaking startups in order to deliver the most suitable package for each of our clients.</p>

                <p>Repair and maintenance services to meet the needs of each individual client - ranging from single item contracts to complete services.</p>

                <div className="service-detail-table">
                    <span className="service-detail-title">HD Tech Skills & Expertise</span>

                    <table>
                        <thead>
                            <tr>
                                <th>skills type</th>
                                <th>description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>e-Commerce</td>
                                <td>Internet Payment Solutions Integration with most payment providers and shopping carts - with particular expertise in ePDQ and WorldPay integration. Advanced ecommerce integration, recurring and deferred payments, devices compatible, invisible and automated transaction processing.</td>
                            </tr>

                            <tr>
                                <td>Web Development and Custom Programming</td>
                                <td>Website design, web development, office, internet and database solutions for any business environment. Shopping carts, booking, registration and content management systems.</td>
                            </tr>

                            <tr>
                                <td>Search Engine Optimisation</td>
                                <td>Our Search Engine Optimisation package includes proven promotion techniques to ensure your web site is well placed to feature at the top of search engines.</td>
                            </tr>

                            <tr>
                                <td>IT and E-commerce Consultancy</td>
                                <td>Ecommerce, website, application and database design, analysis and advice. IT, website and ecommerce project management, telephone and face-to-face consultation.</td>
                            </tr>

                            <tr>
                                <td>Website Security Testing</td>
                                <td>We offer Website and E-commerce Security Testing specializing in shopping cart vulnerabilities - order manipulation, e-shoplifting and admin control panel vulnerabilities.</td>
                            </tr>

                            <tr>
                                <td>Voice-Over Recording</td>
                                <td>Voice-Over Recording Professional IVR, on-hold messaging and web audio voice recording. Recorded by experienced and well-qualified voice-over artistes, our voice recordings are clear, easy to understand, smooth, yet expressive and compelling.</td>
                            </tr>

                            <tr>
                                <td>Ready-made Shopping Solutions</td>
                                <td>We work with, and have developed, a number of ready-made shopping solutions that can be implemented rapidly with minimum fuss, for both small and large-scale e-commerce applications.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        },
        {
            'button': 'Software',
            'subheader': 'Source dor custom and standard computing needs.',
            'img': b2,
            'content': <>
                <p>A trusted business partner and adviser to our customers, HDTech leverages a powerful blend of the best industry-proven practices and leading standards, refined business acumen and deep market understanding, profound technical skills and extensive hands-on experience to meet the toughest challenges that our clients face, help them compete successfully in the dynamically changing IT marketplace and achieve the maximum return on investment.</p>

                <p>The Software <b>services, designs, develops and hosts</b> the products like online archive systems, ERP, Financials, HRMS and any other web based systems.</p>

                <p>HDTech <b>owns several software programs</b> that are State-Of-The-Art in terms of technology and design.</p>

                <ul>
                    <li className="red">FIS (Financial Information Systems)</li>
                    <li className="red">ERP (Enterprise Resource Systems for Plantation Industry)</li>
                    <li className="red">Online Library Systems (Multimedia Archive Software for text, still picture, audio and visual)</li>
                    <li className="red">Document Management Software (Online email, filing and management information systems)</li>
                    <li className="red">MOS Protocol</li>
                    <li className="red">Automated Newsroom Systems</li>
                    <li className="red">CRM (Customer Relationship Management Software)</li>
                    <li className="red">Search Engine</li>
                    <li className="red">Unit Trust Management Systems</li>
                </ul>

                <p>All solutions designed and developed by HDTech are <b>web based</b> and <b>can also be customised to be application based</b> in nature to cater for the current and future requirements for progressive client. With the input of over 10 years of experience, the solutions are geared towards the requirements of the industry.</p>

                <p>HDTech <b>focuses on your core business</b> while our dedicated team of qualified experts is committed to deliver a turn-key solution taking on all and every aspect of your IT needs starting with strategy consulting and concept development, design and architecture, up to deployment, training, maintenance and future enhancement.</p>
            </>
        },
        {
            'button': 'System Integrations',
            'subheader': 'We specializes in integrating systems in It as well as broadcast equipment.',
            'img': b3,
            'content': <>
                <p>HDTech offers products, systems and services that provide interoperable workflow solutions that span the entire broadcast delivery chain. We bring together highly integrated and cost-effective products that are ideal for emerging media business models and for customers upgrading media operations to digital and high-definition services systems integration, design & installation, construction of studios, edit & control rooms.</p>

                <p>Major broadcast companies and financial institutions and universities are testimony to our systems integration capabilities. Our work is designed to meet our client's specific needs and varies greatly to meet the demands of today's business world.</p>

                <span className="service-detail-title">Some of the facilities and studios we work include:-</span>

                <ul>
                    <li>Online Studios Control Rooms</li>
                    <li>Offline Studios Video Audio Production Facilities</li>
                    <li>Lighting Facilities Microwave</li>
                    <li>Video Conference Studios College Laboratories</li>
                    <li>Training / Classrooms Radio Frequency Facilities</li>
                    <li>Edit Facilities Archival Facilities</li>
                    <li>Add sound and display capabilities to existing facilities</li>
                </ul>

                <span className="service-detail-title">Manpower Contract Services, Consultants: Broadcast, Operations & Engineering</span>

                <p>Many companies today need services and support on a temporary or intermittent basis for their business activities. Hiring full time employees for these activities can result in a higher operations cost.</p>

                <p>Technical Operations can supply manpower with the expert services your company needs at savings to you. We can provide maintenance technicians, multimedia technicians, camera operators, editors, installation experts and a variety of other engineering professionals on a project or long-term basis. We also offer technical training services to help companies improve upon their existing operation and bring them up to speed.</p>

                <p>The following manpower assistance is available for short or long term projects:-</p>

                <ul>
                    <li>Audio Operators Audio Video Multimedia Technicians </li>
                    <li>AVID Engineers Broadcast Engineers</li>
                    <li>Broadcast Maintenance Technicians Cameramen</li>
                    <li>Editors Electrical Engineers</li>
                    <li>Field Engineers Graphic Engineers</li>
                    <li>Installation Experts IT, IS, LAN and Web Casting Technicians</li>
                    <li>Production Crew System Integrators</li>
                    <li>Technical Directors Technical Managers</li>
                    <li>Temporary Support Training Technicians</li>
                    <li>Unit Managers Wiring Technicians</li>
                </ul>

                <span className="service-detail-title">AV Multimedia Event Planning and Technical Services</span>

                <p>If you're planning a large-scale or high-level event that involves various aspects of audio/video and multimedia production - we can help you streamline the process and make sure that it all comes together without a hitch. Full range of services that will help you every step along the way, from concept through to completion.</p>

                <p>In addition, we'll also provide a complete estimate for all equipment, staffing and services.</p>

                <p>Multimedia services:-</p>

                <ul>
                    <li>A/V Staffing/Support</li>
                    <li>A/V Multimedia Maintenance</li>
                    <li>Graphic Projection</li>
                    <li>Staging and Set Design</li>
                    <li>Video Production & Editing</li>
                    <li>Video/Audio Conferencing</li>
                    <li>CATV Distribution</li>
                    <li>Streaming Media</li>
                    <li>Equipment Rentals</li>
                </ul>

                <p>HDTech is a partner and distributor of the two main suppliers in broadcast industry that are:-</p>

                <ol>
                    <li>Sony</li>
                    <li>Thomson/Grass Valley</li>
                </ol>
            </>
        },
        {
            'button': 'Network and Communications',
            'subheader': "We excel in the integration of complex networking projects into out customers' existing IT systems.",
            'img': b4,
            'content': <>
                <p>At HDTech, we specialise in a wide range of networking solutions for <b>designing and implementing active and passive network infrastructures, computer rooms and small data centres</b>. We also offer end-to-end solutions ranging from <b>architecture design and consulting to hardware maintenance and system integration implementation</b>.</p>

                <p>Out IT experts are <b>completely adept</b> with World IT's best practices. Our Experts deploy their skills and expertise at your disposal and <b>provide systems that embody quality, value and security</b>. HDTech provides <b>full enterprise solutions</b> to organisations to cater for today's business world.</p>

                <span className="service-detail-title">The main business focus is on the Integration covering:-</span>

                <ul>
                    <li className="red">Web Networks and PORTALS</li>
                    <li className="red">Financial Information Solutions</li>
                    <li className="red">Enterprise Solutions</li>
                    <li className="red">Studio Broadcasting Systems</li>
                    <li className="red">Real-time Newsroom Systems</li>
                    <li className="red">Document Management Systems</li>
                    <li className="red">IPTV</li>
                </ul>
            </>
        },
        {
            'button': 'Broadcasting, Transmission',
            'subheader': 'Systems integrator of broadcast transmitters, antenna and other products from Eurotel/SIRA/RFS.',
            'img': b5,
            'content': <>
                <p>HDTech covers full range of TV and Radio Broadcast antennas including MMDS, DAB and DVB.</p>

                <ol>
                    <li><b>Low Power FM transmitters and RF amplifiers</b>
                        <ul>
                            <li>Range of weather radio transmitters</li>
                            <li>Long tradition of providing high quality audio equipment</li>
                            <li>Range of weather radio transmitters</li>
                        </ul>
                    </li>

                    <li><b>Spinner connector and cable assemblies</b>
                        <ul>
                            <li>Specialists in combining coaxial, optical, and high power contacts in one connector element</li>
                            <li>Broadcasting components</li>
                            <li>Coaxial Measurement devices</li>
                            <li>Waveguide components</li>
                            <li>Rotary joints and slip ring assemblies</li>
                            <li>Switches</li>
                        </ul>
                    </li>

                    <li><b>DRM Transmitters (Digital Radio Mondiale)</b>
                        <ul>
                            <li>AM Transmitters</li>
                            <li>VHF/FM Transmitters</li>
                            <li>TRANSRADIO is specialised in research, development and design of modern AM, VHF/FM and DRM broadcasting systems and delivers customer tailored solutions</li>
                        </ul>
                    </li>

                    <li><b>Video Quality Monitoring Equipment</b>
                        <ul>
                            <li>Video Quality Evaluation Software Solutions</li>
                            <li>Video Quality Evaluation Equipment • Multi-Channel Video/Audio Quality, Automatic Monitoring Equipment</li>
                        </ul>
                    </li>

                    <li><b>Broadcast-quality routers and signal processors handling high-quality audio and HD/SD video streams in all current formats.</b></li>
                </ol>

                <p>We deliver the best products at attractive prices through innovative design and an unsurpassed emphasis on quality This comprehensive product range covers sets new standards for performance, security, and maintenance.</p>
            </>
        },
        {
            'button': 'IPTV Solutions',
            'subheader': 'Source for custom and standard computing needs.',
            'img': b6,
            'content': <>
                <p>"<b>Content is where I expect much of the real money will be made on the Internet</b>" said Bill Gates in 1996. Today, a decade later, increasing number of service providers realise the wisdom in those words. In a world of deregulation, globalisation and increased competition, <b>content remains one of the few elements that allow providers to successfully differentiate their offerings</b>.</p>

                <p>But how can providers deliver high quality content to subscribers? Traditional methods of content delivery, including air, satellite and cable are still available, but they are prohibitively expensive for small and medium size providers and are not globally scalable.</p>

                <p>Fortunately, there are emerging technologies such as <b>Internet Protocol Television (IPTV)</b> which enable <b>low-cost and globally scalable</b> delivery of multimedia content to end users. IPTV technology <b>enables the transport of high quality multimedia content over public networks</b>, such as the Internet. Because providers can leverage on existing global Internet infrastructure, they gain the opportunity to enter into the lucrative TV, Video-on-Demand, and Pay-per-View segments with very low cost and compete successfully with established players like cable and satellite companies.</p>

                <p><b>HDTech offers end-to-end IPTV Solution</b> for distribution of IPTV, Video-on-Demand, Audio-on-Demand, Pay-per-View and other services directly to the TV sets of subscribers. The solution features robust user authentication, powerful billing and CRM capabilities, and intelligent content management. Utilising advanced compression codecs, such as MPEG4 for video and MP3 for audio, <b>the solution allows consistent delivery of high quality multimedia content to subscribers</b> even when network bandwidth is limited.</p>

                <div className="iptv-table">
                    <span className="iptv-header">IPTV Delivery Solution</span>

                    <div className="iptv-img">
                        <img src={iptv} alt="IPTV Delivery Solution"/>
                    </div>
                </div>
            </>
        },
        {
            'button': 'Content Creations',
            'subheader': 'Source for custom and standard computing needs.',
            'img': b7,
            'content': <>
                <p>Years ago, the mantra “Content Is King” found its way into every corner of the internet. The concept spread rapidly. But, many webmasters discovered several hurdles to creating that content. Fortunately, by outsourcing their content needs, they were able to eliminate the hurdles and focus on running their businesses.</p>

                <p>We create custom content for e-learning and TV promotion, product demonstrations and software simulations to meet your organisation's unique training and development needs. All contents are built using latest multimedia tools and cutting edge technologies that can make over learning into an exciting and interactive experience. In e-learning and TV Promotion slots, HDTech will plan, create and build customer's own custom content in any subject area.</p>

                <p>HDTech work with Subject Matter Experts (SMEs) to design courses that cover complex technical topics. We adopted refined tools and a consistent methodology for content development and conversion to quickly provide this high-quality service on a globally consistent basis.</p>

                <span className="service-detail-title">Our instructional designers make content more interactive by:</span>

                <ul>
                    <li>More and more opportunities for on-screen interaction.</li>
                    <li>Quick feedbacks so that users can check and grasp key portion of the subject and review points that needs further guidance.</li>
                    <li>Providing learner a chance to practice new skills through simulations and work exercises.</li>
                </ul>

                <span className="service-detail-title">We specialise in the business of strategic design consulting, multimedia creations & computer arts.</span>

                <ol>
                    <li>Game
                        <ul>
                            <li>Online Gaming</li>
                            <li>Mobile Gaming</li>
                            <li>Desktop Gaming (Windows/Linux/MAC)</li>
                        </ul>
                    </li>

                    <li>Online
                        <ul>
                            <li>Web-Media Campaigns</li>
                            <li>Website Design</li>
                            <li>E-Newsletter</li>
                            <li>Banner Ads</li>
                        </ul>
                    </li>

                    <li>Print
                        <ul>
                            <li>Packaging Design</li>
                            <li>Brochure Design</li>
                            <li>DTP Work</li>
                        </ul>
                    </li>

                    <li>Interactive
                        <ul>
                            <li>E-Catalogs</li>
                            <li>E-Learning</li>
                            <li>TV Adverts (animated)</li>
                            <li>Touch Screen Kiosks</li>
                            <li>Corporate Presentation</li>
                        </ul>
                    </li>

                    <li>Identity
                        <ul>
                            <li>Corporate Identity</li>
                            <li>Corporate Brand Management</li>
                            <li>2D Animation</li>
                            <li>Mobile Animation</li>
                            <li>3D Animation</li>
                        </ul>
                    </li>

                    <li>Stereoscopic
                        <ul>
                            <li>Movies</li>
                            <li>Architectural</li>
                            <li>Walkthroughs</li>
                        </ul>
                    </li>
                </ol>

                <span className="service-detail-title">5 reasons why companies should consider outsourcing your content needs:-</span>

                <ol>
                    <li><b>Hiring Writers Saves Time</b> - Most people find that writing articles, reports, ebooks and other types of content requires a significant amount of time. This is primarily due to the research required for many topics as well as writing and editing the content. Hiring others to do these tasks for you essentially removes that time requirement.</li>
                    <li><b>Outsourcing Can Prevent Procrastination</b> - Many people abhor writing. So, when it's necessary to create content, they procrastinate. The writing doesn't get accomplished. If you operate a business that requires content, this can impact your bottom line.   For example, you may procrastinate writing the report that is necessary for your next email marketing campaign. Without the report completed, the critical email is never sent. Outsource your content needs. You'll naturally stop procrastinating and prevent your business from losing momentum.</li>
                    <li><b>Professional Writers Can Create High-Quality Content</b> - Some people fear writing. Some simply don't write well. If your business is dependent upon well-written content and you are not a good writer, consider hiring others to write your content for you. You'll likely find that content created by professional writers is higher quality than that which you can create yourself. Higher-quality writing can often make the difference between a sale and a missed opportunity.</li>
                    <li><b>Professional Writers Are Trained Researchers</b> - You may occasionally need written content on a subject about which you know very little. In these cases, most people choose to do the research themselves (after procrastinating, of course). But, there is a better option. If you outsource your content creation, you can eliminate the need for you to do research. Professional writers are trained to research all types of subject matter. Instead of struggling with the research, outsource that task to a writer who can do it well.</li>
                    <li><b>Contractors Are Not Employees</b> - Many professional writers work as independent contractors, completing writing jobs only when needed. Unlike employees, when they're not creating content for you, there's no need to continue paying them</li>
                </ol>
            </>
        },
    ]

    const [expand, setExpand] = useState(true)

    const [mobile, setMobile] = useState(w().width < 600 ? true : false)

    var widthHook = w().width

    // animation text & button after page load
    useEffect(() => {
        gsap.fromTo('.text',
            {
                x: 50,
                opacity: 0
            },
            {
                x: 0,
                opacity: 1,
                duration: 1,
                stagger: .5,
                ease: "power4.out"
            }
        )

        gsap.fromTo('.buttons .button',
            {
                x: 50,
                opacity: 0
            },
            {
                x: 0,
                opacity: 1,
                duration: 1,
                stagger: .1,
                ease: "power4.out"
            }
        )
    }, [])

    // change frame width
    useEffect(() => {
        const width = window.innerWidth
        setMobile(width < 600 ? true : false)
        window.scrollTo(0, 0)
        setExpand(true)
    }, [widthHook])

    // service button click & frame change
    useEffect( () => {
        if (mobile) {
            if (expand) { // buka

                // rotate arrow button
                gsap.fromTo('.expand-icon', { rotation: 180 }, { rotation: 0 })

                // display header content
                gsap.to('.service-header .buttons',
                    {
                        display: "flex"
                    }
                ).then(
                    gsap.to('.service-header .buttons',
                        {
                            duration: .5,
                            opacity: 1,
                            ease: "power4.in",
                        }
                    )
                )

                window.scrollTo(0, 0)
            } else { // tutup
                // let headerH = gsap.getProperty(".service-container .buttons", "height")

                // rotate arrow button
                gsap.fromTo('.expand-icon', { rotation: 0 }, { rotation: 180 })

                // hide header content
                gsap.to('.service-header .buttons',
                    {
                        opacity: 0,
                        duration: .5,
                        ease: "power4.out",
                    }
                ).then(
                    gsap.to('.service-header .buttons',
                        {
                            display: "none"
                        }
                    )
                )

                window.scrollTo(0, 0)
            }
        } else {
            gsap.to('.service-header .text, .service-header .buttons',
                {
                    y: 0,
                    opacity: 1,
                    duration: 1,
                    ease: "power4.out",
                }
            )
        }
    }, [expand, mobile])

    // service animation
    useEffect(() => {
        gsap.fromTo('.content-container',
            {
                y: 50,
                opacity: 0
            },
            {
                y: 0,
                opacity: 1,
                duration: 2,
                ease: "power4.out"
            }
        )
    }, [service])

    return (
        <>
            <Navbar/>

            <div className="service">
                <div className="service-container">
                    <div className="header">
                        <div className="service-header">
                            <div className="text" onClick={mobile ? () => setExpand(!expand) : null}>
                                <span>{mobile ? '' : 'learn more about'} our </span><span className="red">services { mobile ?<Icon className="expand-icon" icon="material-symbols:keyboard-arrow-up" /> : ''}</span>
                            </div>

                            <div className="buttons">
                                {data.map( (d, i) =>
                                    <button key={i} className={service === i ? 'button active' : 'button'} onClick={() => setService(i)} >{d.button}</button>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="breakpoint-service"></div>

                    <div className="content">
                        <div className="content-container">
                            <div className="side-image">
                                <img src={data[service].img} alt="" />
                            </div>

                            <div className="service-detail">
                                <div className="service-detail-header">
                                    <span className="service-header">{data[service].button}</span>

                                    <span className="service-subheader">{data[service].subheader}</span>
                                </div>

                                <div className="service-detail-content">
                                {data[service].content}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="breakpoint-service"></div>
                </div>
            </div>

            <Footer />

            <ScrollTop />
        </>
    )
}
export default Service