import { useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import Home from './Components/Home/home'
import About from './Components/About/about'
import Contact from './Components/Contact/contact'
import Service from './Components/Service/service'
import Page404 from './Components/Page404/page404'

import './App.css'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

function App() {

  const [service, setService] = useState(0)

  return (
    <Router>
      <div className='App'>
        <Routes>
          <Route
            path='/'
            element={ <Home service={service} setService={setService}/> }
          >
          </Route>

          <Route
            path='/about'
            element={ <About/> }
          >
          </Route>

          <Route
            path='/contact'
            element={ <Contact/> }
          >
          </Route>

          <Route
            path='/service'
            element={ <Service service={service} setService={setService}/> }
          >
          </Route>

          <Route
            path='*'
            element={ <Page404/> }
          >
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
