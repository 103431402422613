import { useNavigate } from 'react-router-dom'
import './page404.css'

import error404 from '../../Assets/404/pagenotfound.gif'

const Page404 = () => {

    const navigate = useNavigate()

    return (
        <div className="error-main">
            <img alt="Page not found over on requested link" src={error404} />
            <h1>error 404. page not found.</h1>
            <button onClick={() => navigate(-1)}>Go Back</button>
        </div>
    )
}
export default Page404